import '../bootstrap';

//Models
import AppModel from './model.js';

const showAlert = AppModel.showAlert;

import jQuery from 'jquery';
window.$ = jQuery;

$(document).ready(function () {

    /***************** Utilities Functions *****************/
    function addIsolationCover() {
        $('#cover-isolation')
            .removeClass('z-10')
            .addClass('z-30');
    }
    
    function removeIsolationCover() {
        $('#cover-isolation')
            .removeClass('z-30')
            .addClass('z-10');
    }

    /***************** Event Handlers *****************/
    $('.no-double-submit').on('click', function (e) {
        e.preventDefault();
    
        this.disabled = true;
        // you can use data-message on the button to change this text
        this.value = $(this).data('message') ?? 'Soumettre ...';
        this.form.submit();
    });

    // Multi Step Register Form
    if ($('.second-stage-input').find('.error').length > 0 && $('.first-stage-input').find('.error').length == 0) {
        $('#enter-basic-info-step').hide();
        $('#enter-password-step').show();
    } else {
        $('#enter-basic-info-step').show();
        $('#enter-password-step').hide();
    }

    $('#next-register-tab').on('click', function () {
        $('#enter-basic-info-step').hide();
        $('#enter-password-step').fadeIn('slow');
    });

    $('#prev-register-tab').on('click', function () {
        $('#enter-password-step').hide();
        $('#enter-basic-info-step').fadeIn('slow');
    });

    // User dropdown menu , toggle visibility
    $('#user-menu-button').on('click', function () {
        addIsolationCover();
        const $userDropdown = $('#user-dropdown');

        if ($userDropdown.hasClass('hidden')) {
            $userDropdown.removeClass('hidden');
            $userDropdown.addClass('block');
        } else {
            $userDropdown.removeClass('block');
            $userDropdown.addClass('hidden');
        }
    });

    $('#cover-isolation, header').on('click', function (e) {
        // close user dropdown menu , when click anyway outside
        if ($(e.target).is('#user-menu-button') ||
            $(e.target).is('.user-menu-button-icon')) {
            e.preventDefault();
            return;
        }
    
        if ($('#user-dropdown').hasClass('block')) {
            $('#user-dropdown').removeClass('block');
            $('#user-dropdown').addClass('hidden');
        }
    
        removeIsolationCover();
    });

    // close alert module when click on close button
    $('#close-create-alert-modal').on('click', function () {
        $('#create-alert-modal').addClass('hidden');
    });

    // Newsletter Modal
    $('#search-modal-filters-submit').on('click', function (e) {
        e.preventDefault();

        if ($('input[name="form_email"]').val() == '') {
            $('input[name="form_email"]').removeClass('border-1 border-gray-200');
            $('input[name="form_email"]').addClass('border-2 border-red-700 outline-none');
            showAlert($('input[name="form_email"]').data('email-validation-message'));
            return;
        }

        $('#email_alert').val($('input[name="form_email"]').val());

        $.ajax({
            headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') },
            url: $(this).data('create-search-alert-url'),
            type: 'POST',
            dataType: 'json',
            context: this,
            data: $('#search-modal-filters').serialize(),
            success: function (response) {
                $('#create-alert-modal').addClass('hidden');
                showAlert(response.message);
            },
            error: function (response) {
                if(response.responseJSON){
                    showAlert(response.responseJSON.message, 'error');
                }
            },
        });
    });
    
    //handling contact us form submission
    $('#contact-us-form').on('submit', function (e) {
        e.preventDefault();

        const $form = $(this);
        const url = $form.attr('action');

        // set the phone number value
        const $phone = $form.find('input[name="phone"]');
        $phone.val(iti.getNumber(intlTelInput.utils.numberFormat.INTERNATIONAL));

        const payload = $form.serialize();
        const $submitButton = $(this).find('button[type="submit"]');
        const submitButtonText = $submitButton.data('sending-text') ?? 'Soumettre ...';

        // clear all validation errors
        $form.find('.error').empty();

        $submitButton.text(submitButtonText);
        $submitButton.prop('disabled', true);

        $.ajax({
            headers: { 'X-CSRF-TOKEN': $form.find('input[name="_token"]').val() },
            url: url,
            type: 'POST',
            data: payload,
            dataType: 'json',
            context: this,
            success: function (response) {
                showAlert(response.message);   
                $form.find('input').val('');    
                $form.find('textarea').val('');         
            },
            error: function(error) {
                const errors = error.responseJSON.errors;

                // loop through validation errors
                for (const key in errors) {
                    if (errors.hasOwnProperty(key)) {
                        const errorMessages = errors[key];
                        const $errorsList = $form.find(`#${key}_error`);
                        $errorsList.empty();
                        errorMessages.forEach(message => {
                            $errorsList.append(`<li>${message}</li>`);
                        });
                    }
                }
            },
            complete: function(){
                $submitButton.text($submitButton.attr('aria-label'));
                $submitButton.prop('disabled', false);
            }
        });
    });
});


